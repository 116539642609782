import SystemLeftBorder from "./SystemLeftBorder";
import SystemRightBorder from "./SystemRightBorder";


export default function SystemHud() {

  return (
    <>
      <SystemLeftBorder />
      <SystemRightBorder />
    </>
  )
}